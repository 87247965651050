import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import { Link, graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { LazyLoadComponent } from "react-lazy-load-image-component"
// import ScrollBtn from "../button/scroll-btn"
import useMediaQuery from "../../hooks/useMediaQuery"
import loadable from "@loadable/component"
import Swiper from "react-id-swiper"
import { Pagination, Navigation } from "swiper/js/swiper.esm"
// import "./hero.css"

// const HeroLogo = loadable(() => import("./hero-logos"))
// const ScrollBtn = loadable(() => import("../button/scroll-btn"))

const SwiperHero = [
  {
    name: "firstVideoSwiper",
    subtitle: "The Quandary Difference",
    title: "Your Trusted Advisor in Driving Efficiency & Growth",
    embedlink: "https://www.youtube.com/embed/yr7M25g9rBc?si=33hRKolwwxCi3w01",
  },
  {
    name: "secondVideoSwiper ",
    subtitle: "Citizen Development Week",
    title: "Bridging IT Support Gaps and Ensuring Security",
    embedlink: "https://www.youtube.com/embed/FlXofLWRspE",
    // label: "Learn More",
    // url: "/our-approach",
    // anchorLink: "/#it-consult ",
  },
  {
    name: "thirdVideoSwiper  ",
    subtitle: "Data Governance",
    title: "The Secret to Low-Code & No-Code Success",
    embedlink: "https://www.youtube.com/embed/mTtniUywtng?si=JdGCNeeGYhE-mVVz",
    // label: "Learn More",
    // url: "/offline-application-development-construction-management/",
    // anchorLink: "/#it-consult",
  },
  {
    name: "fourthVideoSwiper",
    subtitle: "Decoding Gray Work",
    title: "Unlocking a New Era of Workforce Productivity",
    embedlink: "https://www.youtube.com/embed/LKlFnt710X0?si=0lsHvi3UyGJXpWQV",
    // label: "Learn More",
    // url: "/retail-automation",
    // anchorLink: "/#it-consult",
  },
  {
    name: "fifthVideoSwiper",
    subtitle: "Operational Efficiency",
    title: "Predicting Market Drivers and Business Impacts",
    embedlink: "https://www.youtube.com/embed/bYyqqB6Ni2s?si=nSHO93sCPPf0Bz_v",
    // label: "Learn More",
    // url: "/retail-automation",
    // anchorLink: "/#it-consult",
  },
]

const VideoSlider = () => {
  // const { heroImage } = useStaticQuery(
  //   graphql`
  //     {
  //       heroImage: file(relativePath: { eq: "new-hero.jpg" }) {
  //         childImageSharp {
  //           gatsbyImageData(
  //             width: 1170
  //             layout: CONSTRAINED
  //             formats: [AUTO, WEBP, AVIF]
  //           )
  //         }
  //       }
  //     }
  //   `
  // )

  // const heroImageData = getImage(heroImage)

  // // Use like this:
  // const bgImage = convertToBgImage(heroImageData)

  // const isDesktop = useMediaQuery("(min-width: 768px)")

  const params = {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 30,
    effect: "fade",
    breakpoints: {
      1024: {
        slidesPerView: 1,
        spaceBetween: 50,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    containerClass: "swiper-container swiper-videoslider",
    nested: true,
    loop: "true",
    autoplay: {
      delay: 15000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  const styles = {
    fontFamily: "Futura,sans-serif",
    marginBottom: "1.5rem",
    fontWeight: 500,
  }

  return (
    <>
      <Swiper {...params}>
        {SwiperHero?.map((item, index) => (
          <div
            className={`videoslider-banner relative ${item?.name}`}
            key={index}
          >
            <div className="videoslider-overlay" />
            <div className="container">
              <div className="row pb-3">
                <div className="col-lg-6 col-xl-6 col-md-6">
                  <div className="hero-custom-content-videoslider-title review-content text-white">
                    <h3 className="text-light-blue mb-0 mt-2 videoslider-subtitle">
                      {item?.subtitle}
                    </h3>
                    <h1
                      className="page-videoslider-title"
                      style={{
                        marginBottom: "0.5rem",
                        display: "inline",
                      }}
                    >
                      {item?.title}
                    </h1>

                    {/* <div className="hero-button">
                      <Link
                        to={`${item?.url}`}
                        className="btn btn-primary btn-arrow mr-2 mt-4 text-center text-capitalize py-3"
                        style={{ marginTop: "auto" }}
                      >
                        {item?.label}
                      </Link>
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-6 col-xl-6 col-md-6">
                  <div className="hero-custom-content-videoslider review-content text-white">
                    <div class="embed-container">
                      <iframe
                        src={`${item?.embedlink}`}
                        frameborder="0"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="col-lg-12 col-md-12 pb-2 d-flex justify-content-end"
                  style={{ position: "relative" }}
                >
                  <HeroLogo />
                </div> */}
              </div>
            </div>
            {/* <div className="px-5 swiper-scroll-down">
              <div className="arrow bounce" />
            </div> */}
            {/* <div className="swiperOverlay"></div> */}
          </div>
        ))}
      </Swiper>
    </>
  )
}

export default VideoSlider
